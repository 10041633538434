<template>
  <div class="order-mock-list">
    <div class="d-flex justify-space-between align-center mb-4">
      <h1>Order List</h1>
      <v-btn small color="primary" @click="createOrder">Create Order</v-btn>
    </div>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <div v-else>
      <table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Customer Name</th>
            <th>Email</th>
            <th>Payment Status</th>
            <th>Shipping Status</th>
            <th>Status</th>
            <th>Created Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id">
            <td>{{ order.id }}</td>
            <td>{{ getOrderData(order).name }}</td>
            <td>{{ getOrderData(order).buyer_email }}</td>
            <td>{{ getOrderData(order).is_paid === true ? 'Paid' : 'Unpaid' }}</td>
            <td>{{ getOrderData(order).is_shipped === true ? 'Shipped' : 'Unshipped' }}</td>
            <td>{{ getOrderData(order).status }}</td>
            <td>{{ formatDate(getOrderData(order).created_timestamp) }}</td>
            <td>
              <v-btn small color="primary" @click="viewOrderDetails(order)">View</v-btn>
              <v-btn small color="primary" class="ml-2" @click="editOrderDetails(order)">Edit</v-btn>
              <v-btn small color="error" class="ml-2" @click="deleteOrder(order.id)">Delete</v-btn>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination-controls mt-4">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          @input="fetchOrders"
        ></v-pagination>
      </div>

      <v-dialog v-model="detailDialog" max-width="800px">
        <v-card>
          <v-card-title>Order Details</v-card-title>
          <v-card-text v-if="selectedOrder">
            <pre>{{ JSON.stringify(getOrderData(selectedOrder), null, 2) }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="detailDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OrderMockList',
  data() {
    return {
      orders: [],
      loading: false,
      currentPage: 1,
      totalPages: 1,
      detailDialog: false,
      selectedOrder: null
    };
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      this.loading = true;
      try {
        const response = await axios.get('/admin/mock/order', {
          params: { page: this.currentPage }
        });
        this.orders = response.data.data;
        this.currentPage = response.data.current_page;
        this.totalPages = response.data.last_page;
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        this.loading = false;
      }
    },
    getOrderData(order) {
      try {
        return JSON.parse(order.data);
      } catch (e) {
        console.error('Error parsing order data:', e);
        return {};
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return 'N/A';
      return new Date(timestamp * 1000).toLocaleString();
    },
    viewOrderDetails(order) {
      this.selectedOrder = order;
      this.detailDialog = true;
    },
    editOrderDetails(order) {
      this.$router.push({
        name: 'order-mock-form',
        params: { receipt_id: order.id, data: JSON.stringify(order) },
      });
    },
    async deleteOrder(orderId) {
      if (confirm('Are you sure you want to delete this order?')) {
        try {
          await axios.delete(`/admin/mock/order/${orderId}`);
          this.fetchOrders();
        } catch (error) {
          console.error('Error deleting order:', error);
        }
      }
    },
    createOrder() {
      this.$router.push({
        name: 'order-mock-form',
      });
    }
  }
};
</script>

<style scoped>
.order-mock-list {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}
</style>
